// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  CombinedForm:'/CombinedForm',
  parkingLocations : 'parking-locations',
  completeBooking:'/complete-booking',
  paymentSuccessful:'payment-successful',
  parkingDetails:'/parking-details/:id/:checkInDate',
  // completeBooking:'/complete-booking',
  bookings:'/bookings',
  booking:'/booking/:id',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/admin/login`,
      register: `${ROOTS.AUTH}/admin/register`,
      forgotPassword: `${ROOTS.AUTH}/admin/forgot-password`,
      newPassword: `${ROOTS.AUTH}/admin/new-password`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    lotOwnerPlan: {
      root: `${ROOTS.DASHBOARD}/lotOwnerPlan`,
      list: `${ROOTS.DASHBOARD}/lotOwnerPlan/list`,
      new: `${ROOTS.DASHBOARD}/lotOwnerPlan/new`,
      edit: (id) => `${ROOTS.DASHBOARD}/lotOwnerPlan/${id}/edit`,
    },
    lotOwnerEnquiry: {
      root: `${ROOTS.DASHBOARD}/lotOwnerEnquiry`,
      list: `${ROOTS.DASHBOARD}/lotOwnerEnquiry/list`,
      // new: `${ROOTS.DASHBOARD}/lotOwnerPlan/new`,
      // edit: (id) => `${ROOTS.DASHBOARD}/lotOwnerPlan/${id}/edit`,
    },

    bookingScanner : `${ROOTS.DASHBOARD}/bookingScanner`,

    taxAndFees : `${ROOTS.DASHBOARD}/taxAndFees`,

    lotOwnerSettlements : {
      root : `${ROOTS.DASHBOARD}/lotOwnerSettlements`,
      list : `${ROOTS.DASHBOARD}/lotOwnerSettlements/list`,
      report : (lotOwnerId) => `${ROOTS.DASHBOARD}/lotOwnerSettlements/reports/${lotOwnerId}`
    },

    settlements : {
      root : `${ROOTS.DASHBOARD}/settlements`,
      list : `${ROOTS.DASHBOARD}/settlements/list`,
    },

    parkingFacility: {
      root: `${ROOTS.DASHBOARD}/parkingFacility`,
      list: `${ROOTS.DASHBOARD}/parkingFacility/list`,
      new: `${ROOTS.DASHBOARD}/parkingFacility/new`,
      // edit: (id) => `${ROOTS.DASHBOARD}/lotOwnerPlan/${id}/edit`,
    },
    parkingLocationType: {
      root: `${ROOTS.DASHBOARD}/parkingLocationType`,
      list: `${ROOTS.DASHBOARD}/parkingLocationType/list`,
      new: `${ROOTS.DASHBOARD}/parkingLocationType/new`,
      // edit: (id) => `${ROOTS.DASHBOARD}/lotOwnerPlan/${id}/edit`,
    },
    parkingLocations: {
      root: `${ROOTS.DASHBOARD}/parkingLocations`,
      list: `${ROOTS.DASHBOARD}/parkingLocations/list`,
      new: `${ROOTS.DASHBOARD}/parkingLocations/new`,
      // edit: (id) => `${ROOTS.DASHBOARD}/lotOwnerPlan/${id}/edit`,
    },
    ownerParkingLocations: {
      root: `${ROOTS.DASHBOARD}/ownerParkingLocations`,
      list: `${ROOTS.DASHBOARD}/ownerParkingLocations/list`,
      new: `${ROOTS.DASHBOARD}/ownerParkingLocations/new`,
      // edit: (id) => `${ROOTS.DASHBOARD}/lotOwnerPlan/${id}/edit`,
    },
    parkings: {
      root: `${ROOTS.DASHBOARD}/parkings`,
      list: `${ROOTS.DASHBOARD}/parkings/list`,
      new: `${ROOTS.DASHBOARD}/parkings/new`
    },
    destinationPickups: {
      root: `${ROOTS.DASHBOARD}/destinationPickups`,
      list: `${ROOTS.DASHBOARD}/destinationPickups/list`,
      destinations: (parkingId) => `${ROOTS.DASHBOARD}/destinationPickups/destinations/${parkingId}`
    },
    lotOwnerDestinationPickups: {
      root: `${ROOTS.DASHBOARD}/lotOwnerDestinationPickups`,
      list: `${ROOTS.DASHBOARD}/lotOwnerDestinationPickups/list`,
      destinations: (parkingId) => `${ROOTS.DASHBOARD}/lotOwnerDestinationPickups/lotOwnerDestinations/${parkingId}`
    },
    shuttleRider: {
      root: `${ROOTS.DASHBOARD}/shuttleRider`,
      list: `${ROOTS.DASHBOARD}/shuttleRider/list`,
      new: `${ROOTS.DASHBOARD}/shuttleRider/new`
    },
    ownerParkings: {
      root: `${ROOTS.DASHBOARD}/ownerParkings`,
      list: `${ROOTS.DASHBOARD}/ownerParkings/list`,
      new: `${ROOTS.DASHBOARD}/ownerParkings/new`
    },
    bookings: {
      root: `${ROOTS.DASHBOARD}/bookings`,
      list: `${ROOTS.DASHBOARD}/bookings/list`,
      new: `${ROOTS.DASHBOARD}/bookings/new`
    },
    shuttle: {
      root: `${ROOTS.DASHBOARD}/shuttle`,
      list: `${ROOTS.DASHBOARD}/shuttle/list`,
      new: `${ROOTS.DASHBOARD}/shuttle/new`
    },
    lotOwnerShuttle: {
      root: `${ROOTS.DASHBOARD}/lotOwnerShuttle`,
      list: `${ROOTS.DASHBOARD}/lotOwnerShuttle/list`,
      new: `${ROOTS.DASHBOARD}/lotOwnerShuttle/new`
    },
    rider: {
      root: `${ROOTS.DASHBOARD}/rider`,
      list: `${ROOTS.DASHBOARD}/rider/list`,
      new: `${ROOTS.DASHBOARD}/rider/new`,
      bookings: (riderId) => `${ROOTS.DASHBOARD}/rider/rider-bookings/${riderId}`
    },
    lotOwnerRider: {
      root: `${ROOTS.DASHBOARD}/lotOwnerRider`,
      list: `${ROOTS.DASHBOARD}/lotOwnerRider/list`,
      new: `${ROOTS.DASHBOARD}/lotOwnerRider/new`
    },
    ownerBookings: {
      root: `${ROOTS.DASHBOARD}/ownerBookings`,
      list: `${ROOTS.DASHBOARD}/ownerBookings/list`,
      new: `${ROOTS.DASHBOARD}/ownerBookings/new`
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
      list : `${ROOTS.DASHBOARD}/product/parkinglist`,
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
